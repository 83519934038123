import React from 'react';
import './SideNav.scss'
import history from "../history";

const SideNav = ({ isOpen, close }) => {

  const goToPage = (path) => {
    history.push(path);
    close()
  }

  return (
    <div className={isOpen ? 'sidenav open' : 'sidenav'}>
      <div className={'fixed'}>
        <div style={{ padding: 16 }}>
          <div style={{ textAlign: 'center', width: 280 - 32 }}>
            <img style={{ width: 180 }} src={process.env.PUBLIC_URL + '/resources/round.png'} alt={""} />
          </div>
          <div style={{ textAlign: 'center', width: 280 - 32, marginTop: 16, color: "#F0EFF4" }}>
            <b>Associate Research Scientist</b>
            <p>Disney Research</p>
          </div>
          <div style={{ textAlign: 'center', width: 280 - 32, marginBottom: 16 }}>
            <a href={"mailto:me@maike-paetzel.de"}><img style={{ height: 25 }}
                                                           src={process.env.PUBLIC_URL + '/resources/email.png'}
                                                           alt={""} /></a>
            <a href={"https://www.linkedin.com/in/maike-paetzel/"} style={{ marginLeft: 10 }} target={"_blank"}><img
              style={{ height: 25 }} src={process.env.PUBLIC_URL + '/resources/linkedin.png'} alt={""} /></a>
            <a href={"https://scholar.google.com/citations?user=uu2YSXQAAAAJ"} style={{ marginLeft: 10 }}
               target={"_blank"}><img style={{ height: 25 }}
                                      src={process.env.PUBLIC_URL + '/resources/googleScholar.png'} alt={""} /></a>
            <a href={"https://www.researchgate.net/profile/Maike_Paetzel"} style={{ marginLeft: 10 }} target={"_blank"}><img
              style={{ height: 25 }} src={process.env.PUBLIC_URL + '/resources/researchgate.png'} alt={""} /></a>
          </div>
          <div className={"hline"}></div>
          <div style={{ textAlign: 'left', width: 280 - 32, paddingTop: 16 }}>
            <div className={'side-link'} onClick={() => goToPage('/')}>Home</div>
            <a href={'https://www.maike-paetzel.de/papers/Maike_Paetzel_CV.pdf'} target={'_blank'} className={'side-link'}>CV</a>
            <div className={'side-link'} onClick={() => goToPage('/research-projects')}>Research Projects</div>
            <div className={'side-link'} onClick={() => goToPage('/publications')}>Publications</div>
            <div className={'side-link'} onClick={() => goToPage('/teaching')}>Teaching</div>
            <div className={'side-link'} onClick={() => goToPage('/academic-services')}>Academic Services</div>
            <div className={'side-link'} onClick={() => goToPage('/scientific-outreach')}>Scientific Outreach</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideNav;