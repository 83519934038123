import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, ListGroupItem } from 'reactstrap'
import BibtexParse from 'bibtex-parse-js'

const PublicationsContainer = () => {

  const [conferencePublications, setConferencePublications] = useState([]);
  const [nonPeerPublications, setNonPeerPublications] = useState([]);

  const loadPublications = async () => {

    fetch('/sample.txt')
      .then((r) => r.text())
      .then(text => {
        console.log(text);
      })


    const response = await fetch(process.env.PUBLIC_URL + '/resources/references.bib');
    console.log(response);
    const text = await response.text();
    console.log(text)
    const sample = BibtexParse.toJSON(text);
    console.log(sample);

    const correctlyParsedConferenceProceedings = sample.filter(e => {
      return e.entryType === "inproceedings"
    }).map(e => {
      return {
        title: e.entryTags.title.replace("{", "").replace("}", "").replace("``", "\""),
        author: parseAuthors(e.entryTags.author).replace("{\\\"o}", "ö"),
        venue: e.entryTags.booktitle.replace("{", "").replace("}", "").replace("\\&", "&"),
        year: e.entryTags.year,
        month: e.entryTags.month,
        url: e.entryTags.url
      }
    }).sort(function (a, b) {
      return parseFloat(b.year + "." + b.month) - parseFloat(a.year + "." + a.month)
    })

    setConferencePublications(correctlyParsedConferenceProceedings);

    const correctlyParsedNonPeerProceedings = sample.filter(e => {
      return e.entryType === "article"
    }).map(e => {
      return {
        title: e.entryTags.title.replace("{", "").replace("}", "").replace("``", "\""),
        author: parseAuthors(e.entryTags.author).replace("{\\\"o}", "ö"),
        venue: e.entryTags.journal.replace("{", "").replace("}", "").replace("}", "").replace("\\&", "&"),
        year: e.entryTags.year,
        month: e.entryTags.month,
        url: e.entryTags.url
      }
    }).sort(function (a, b) {
      return parseFloat(b.year + "." + b.month) - parseFloat(a.year + "." + a.month)
    })

    setNonPeerPublications(correctlyParsedNonPeerProceedings);

  };

  const parseAuthors = (authors) => {
    const author_list = authors.split(" and ");
    const correctlyParsed = author_list.map(e => {
      return e.split(", ")[1].slice(0, 1) + ". " + e.split(", ")[0];
    });

    let final_authors = correctlyParsed.slice(0, correctlyParsed.length - 1).join(", ")

    if (correctlyParsed.length > 1) {
      final_authors += ' and '
    }
    final_authors += correctlyParsed[correctlyParsed.length - 1]
    return final_authors
  }

  useEffect(() => {
    loadPublications()
  }, [])


  const conference = conferencePublications.map(e => {
    return <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
      <div style={{ marginBottom: 10 }}><b>{e.title}</b></div>
      <div>{e.author}</div>
      <div>
        <i>{e.venue}</i> ({e.year}). <a href={e.url} target={"_blank"}
                                        style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
        src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> PDF</a>
      </div>
    </ListGroupItem>
  })
  const nonpeer = nonPeerPublications.map(e => {
    return <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
      <div style={{ marginBottom: 10 }}><b>{e.title}</b></div>
      <div>{e.author}</div>
      <div>
        <i>{e.venue}</i> ({e.year}). <a href={e.url} target={"_blank"}
                                        style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
        src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> PDF</a>
      </div>
    </ListGroupItem>
  })
  return <Container fluid style={{ paddingTop: 56 + 16, paddingBottom: 40 }}>
    <div className="content-frame">
      <h1 style={{ fontWeight: "heavy", marginBottom: 20, color: "#432342" }}>Publications</h1>
      <div style={{ height: 24 }} />
      <h3 style={{ fontWeight: "heavy", color: "#5C475A" }}>Peer-Reviewed Conference Proceedings</h3>
      <div style={{ height: 8 }} />
      {conference}
      <div style={{ height: 24 }} />
      <h3 style={{ fontWeight: "heavy", color: "#5C475A" }}>Posters, Interactive Sessions & Invited Articles (not
        peer-reviewed)</h3>
      <div style={{ height: 8 }} />
      {nonpeer}
    </div>
  </Container>


};

export default withRouter(PublicationsContainer)
