import { withRouter } from "react-router-dom";
import { Container, ListGroupItem } from "reactstrap";
import React, { useEffect, useState } from "react";
import BibtexParse from "bibtex-parse-js";

const Teaching = () => {

  const [bachelorTheses, setBachelorTheses] = useState([]);
  const [masterTheses, setMasterTheses] = useState([]);

  const loadPublications = async () => {

    fetch('/sample.txt')
      .then((r) => r.text())
      .then(text => {
        console.log(text);
      })


    const response = await fetch(process.env.PUBLIC_URL + '/resources/references.bib');
    console.log(response);
    const text = await response.text();
    console.log(text)
    const sample = BibtexParse.toJSON(text);
    console.log(sample);

    const correctlyParsedBSTheses = sample.filter(e => {
      return e.entryType === "thesis"
    }).map(e => {
      return {
        title: e.entryTags.title.replace("{", "").replace("}", "").replace("``", "\"").replace("--", "-"),
        author: parseAuthors(e.entryTags.author).replace("\\\"{a}\\\"{a}", "ää"),
        year: e.entryTags.year,
        url: e.entryTags.url
      }
    }).sort(function (a, b) {
      return parseFloat(b.year) - parseFloat(a.year)
    })

    setBachelorTheses(correctlyParsedBSTheses);

    const correctlyParsedMSTheses = sample.filter(e => {
      return e.entryType === "masterthesis"
    }).map(e => {
      return {
        title: e.entryTags.title.replace("{", "").replace("}", "").replace("``", "\"").replace("--", "-"),
        author: parseAuthors(e.entryTags.author).replace("\\\"{a}\\\"{a}", "ää"),
        year: e.entryTags.year,
        url: e.entryTags.url
      }
    }).sort(function (a, b) {
      return parseFloat(b.year) - parseFloat(a.year)
    })

    setMasterTheses(correctlyParsedMSTheses);

  };

  const parseAuthors = (authors) => {
    const author_list = authors.split(" and ");
    const correctlyParsed = author_list.map(e => {
      return e.split(", ")[1].slice(0, 1) + ". " + e.split(", ")[0];
    });

    let final_authors = correctlyParsed.slice(0, correctlyParsed.length - 1).join(", ")

    if (correctlyParsed.length > 1) {
      final_authors += ' and '
    }
    final_authors += correctlyParsed[correctlyParsed.length - 1]
    return final_authors
  }

  useEffect(() => {
    loadPublications()
  }, [])


  const bstheses = bachelorTheses.map(e => {
    return <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
      <div style={{ marginBottom: 10 }}><b>{e.title}</b></div>
      <div>{e.author} ({e.year}). {e.url ?
        <a href={e.url} target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
          src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> PDF</a> : ""}
      </div>
    </ListGroupItem>
  })
  const mstheses = masterTheses.map(e => {
    return <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
      <div style={{ marginBottom: 10 }}><b>{e.title}</b></div>
      <div>{e.author} ({e.year}). {e.url ?
        <a href={e.url} target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
          src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> PDF</a> : ""}
      </div>
    </ListGroupItem>
  })

  return <Container fluid style={{ paddingTop: 56 + 16, paddingBottom: 40 }}>
    <div className="content-frame">
      <h1 style={{ fontWeight: "heavy", marginBottom: 20, color: "#432342" }}>Teaching & Advising</h1>
      <div style={{ height: 24 }} />
      <h3 style={{ fontWeight: "heavy", color: "#5C475A" }}>Teaching</h3>
      <div style={{ height: 8 }} />
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Multimodal Dialogue in Human-Robot-Interaction</b></div>
        <div>Course Development, Main Teacher</div>
        <div>University of Potsdam (SoSe 22), <a
            href={"https://puls.uni-potsdam.de/qisserver/rds?state=verpublish&status=init&vmfile=no&publishid=92510&moduleCall=webInfo&publishConfFile=webInfo&publishSubDir=veranstaltung"}
            target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
            src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Interaktionsdesign für sprachbasierte Assistenzsysteme [Interaction Design for language-based assistant systems]</b></div>
        <div>Course Development, Main Teacher</div>
        <div>University of Potsdam (WiSe 21/22), <a
            href={"https://puls.uni-potsdam.de/qisserver/rds?state=verpublish&status=init&vmfile=no&publishid=89402&moduleCall=webInfo&publishConfFile=webInfo&publishSubDir=veranstaltung"}
            target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
            src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Programmierung I [Programming I]</b></div>
        <div>Main Teacher</div>
        <div>University of Potsdam (WiSe 21/22), <a
            href={"https://puls.uni-potsdam.de/qisserver/rds?state=verpublish&status=init&vmfile=no&moduleCall=modulansicht&publishConfFile=modulverwaltung&publishSubDir=up/modulbearbeiter&&modul.modul_id=1818&menuid=&topitem=Modulbeschreibung&subitem="}
            target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
            src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Cooperative Task-Solving with an Embodied Artificial Agent</b></div>
        <div>Course Development, Main Teacher</div>
        <div>University of Potsdam (SoSe 2021), <a
            href={"https://puls.uni-potsdam.de/qisserver/rds?state=verpublish&status=init&vmfile=no&moduleCall=modulansicht&publishConfFile=modulverwaltung&publishSubDir=up/modulbearbeiter&modul.modul_id=488"}
            target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
            src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Formale Sprachen und Automaten in der Computerlinguistik [Formal Languages and Automata in Computational Linguistikcs]</b></div>
        <div>Main Teacher</div>
        <div>University of Potsdam (SoSe 2021), <a
            href={"https://puls.uni-potsdam.de/qisserver/rds?state=verpublish&status=init&vmfile=no&moduleCall=modulansicht&publishConfFile=modulverwaltung&publishSubDir=up/modulbearbeiter&&modul.modul_id=1551&menuid=&topitem=Modulbeschreibung&subitem="}
            target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
            src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Interaktionsdesign für soziale Roboter [Interaction Design for Social Robots]</b></div>
        <div>Course Development, Main Teacher</div>
        <div>University of Potsdam (WiSe 20/21), <a
            href={"https://puls.uni-potsdam.de/qisserver/rds?state=verpublish&publishContainer=lectureContainer&publishid=85646"}
            target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
            src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Intelligent Interactive Systems</b></div>
        <div>Course Development, Lecturer, Teaching Assistant</div>
        <div>Uppsala University (2016 - 2020), <a
          href={"http://www.uu.se/en/admissions/master/selma/kursplan/?kpid=30676&lasar=16\\%2F17&typ=1"}
          target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
          src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>System Design with a User Perspective</b></div>
        <div>Course Development, Lecturer, Teaching Assistant</div>
        <div>Uppsala University (2017 - 2020), <a
          href={"http://www.uu.se/en/admissions/master/selma/kursplan/?kpid=33820&type=1"} target={"_blank"}
          style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img src={process.env.PUBLIC_URL + '/resources/link.png'}
                                                                  style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Advanced Interaction Design</b></div>
        <div>Lecturer, Teaching Assistant</div>
        <div>Uppsala University (2019), <a
          href={"http://www.uu.se/en/admissions/master/selma/Kurser/?kKod=1MD001&typ=1"} target={"_blank"}
          style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img src={process.env.PUBLIC_URL + '/resources/link.png'}
                                                                  style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>User Interface Programming</b></div>
        <div>Lecturer, Teaching Assistant</div>
        <div>Uppsala University (2016 - 2017), <a
          href={"http://www.uu.se/en/admissions/master/selma/kursplan/?kpid=30749&lasar=16\\%2F17&typ=1"}
          target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
          src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Database Design I</b></div>
        <div>Teaching Assistant</div>
        <div>Uppsala University (2015), <a
          href={"http://www.uu.se/en/admissions/master/selma/kursplan/?kpid=30209&lasar=16\\%2F17&typ=1"}
          target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
          src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Syllabus</a></div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Grundlagen von Datenbanken [Basic Principles of Databases]</b></div>
        <div>Teaching Assistant</div>
        <div>University of Hamburg (2013/14), <a
          href={"https://vsis-www.informatik.uni-hamburg.de/vsis/teaching/coursekvv/305"} target={"_blank"}
          style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img src={process.env.PUBLIC_URL + '/resources/link.png'}
                                                                  style={{ height: 15 }} /> Course concept (German)</a>
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Softwareentwicklung II [Software Development II]</b></div>
        <div>Teaching Assistant</div>
        <div>University of Hamburg (2011), <a href={"https://swa.informatik.uni-hamburg.de/lehrkonzept/se2.html"}
                                              target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
          src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Course concept (German)</a>
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}><b>Softwareentwicklung I [Software Development I]</b></div>
        <div>Teaching Assistant</div>
        <div>University of Hamburg (2010 - 2011), <a href={"https://swa.informatik.uni-hamburg.de/lehrkonzept/se1.html"}
                                                     target={"_blank"}
                                                     style={{ color: "#5C475A", whiteSpace: "nowrap" }}><img
          src={process.env.PUBLIC_URL + '/resources/link.png'} style={{ height: 15 }} /> Course concept (German)</a>
        </div>
      </ListGroupItem>
      <div style={{ height: 24 }} />
      <h3 style={{ fontWeight: "heavy", color: "#5C475A" }}>Advising</h3>
      <div style={{ height: 8 }} />
      <p>In the following projects I have been either involved in the role of the supervisor, working together with the
        students on a day-to-day basis,
        or as a review assistant, ensuring the project fulfills the scientific quality standards of the university and
        helping with writing the thesis report.</p>
      <div style={{ height: 4 }} />

      <h5 style={{ fontWeight: "heavy", color: "#5C475A" }}>Master Theses</h5>
      <div style={{ height: 8 }} />
      {mstheses}
      <h5 style={{ fontWeight: "heavy", color: "#5C475A" }}>Bachelor Theses</h5>
      <div style={{ height: 8 }} />
      {bstheses}
    </div>
  </Container>

}

export default withRouter(Teaching)