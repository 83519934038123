import { withRouter } from "react-router-dom";
import { Col, Container, ListGroupItem, Row } from "reactstrap";
import React from "react";

const Research = () => {

  return <Container fluid style={{ paddingTop: 56 + 16, paddingBottom: 40 }}>
    <div className="content-frame">
      <h1 style={{ fontWeight: "heavy", marginBottom: 20, color: "#432342" }}>Reseach Projects</h1>
      <div style={{ height: 24 }} />
      <h2 style={{ fontWeight: "heavy",fontSize:  "1.75rem", color: "#5C475A" }}>Ongoing</h2>
      <div style={{ height: 8 }} />
      Unfortunately, I am not able to share all the interesting projects I am currently working on at Disney Research.
      In the following, I am listing two recent projects that we published on and that are exemplary of the research I am
      currently engaged in.
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, paddingBottom: 24}}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Automatic Evaluation Framework for Social Conversations with Robots</b></h3>
          <div>
            When deploying social robots in the wild, it is crucial for developers to gain an understanding of how the
            interactions between the robot and its human conversational partners are progressing.
            Unlike in traditional task-based settings in which a human and a robot work on a tangible outcome that can
            serve as a proxy for how well a conversation is going, social settings require a deeper understanding of the
            underlying interaction dynamics. In our work, we assess potential features of a robot having social
            conversations in a multi-party, multi-session setting and correlate them with how people rate their interaction.
            We also work on a framework that combines the features into a model that can automatically assess an
            ongoing conversation and determine its performance.
          </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, paddingBottom: 24}}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Improving a Robot’s Turn-Taking Behavior in Dynamic Multiparty Interactions</b></h3>
        <div>
          We are interested in developing a robust and natural turn-taking behavior for a social agent to engage a
          dynamically changing group in a conversation. For that, we assess features derived from more than 9,000 dialogue samples
          and train model that result in better decision policies for our robots to engage in.
        </div>
      </ListGroupItem>

      <div style={{ height: 24 }} />
      <h3 style={{ fontWeight: "heavy", color: "#5C475A" }}>Previous</h3>
      <ListGroupItem className="justify-content-between" style={{ marginBottom: 20, paddingBottom: 24 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>The Rapid Dialogue Game - Map</b></h3>
        <div>
          <img style={{ width: 200, float: "left", marginRight: 10 }}
               src={process.env.PUBLIC_URL + '/resources/map_demo_mikael.png'} alt={""} />
          <p>The domain I use for most of my current research is called <i>Rapid Dialogue Game - Map</i> (RDG-Map) and
            is a cooperative, two-player game about
            locating countries on the world map. One player acts as the Director in the game, who sees one country
            highlighted on the world map. The Director needs
            to verbally describe the country to the Matcher, who aims to select the same country as fast as possible.
          </p>

          <p>We have developed both an online version of the game that can be played with the unembodied agent Nellie
            and a version involving
            our Furhat robot Neil as the game partner.</p>

          <p> There are currently several smaller research projects I am involved in that use the RDG-Map game as a
            domain.</p>
        </div>
        <Row>
          <Col xs={{ size: 12 }} lg={{ size: 6 }}>
            <ListGroupItem className="justify-content-between" style={{marginTop: 20}}>
              <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>The Development of Uncanny Feelings over Time</b></h3>
              <div>The literature on the uncanny valley effect is very focused on first impressions. From human
                psychology,
                however, we know that first impressions are not necessarily an accurate predictor of the long-term
                relationship. In my research, I want to find out if initial
                uncanny feelings towards a robot can be overcome over the course of repeated interactions
                and what in the robot's behavior can foster or hinder overcoming the uncanny feelings.
              </div>
            </ListGroupItem>
          </Col>
          <Col xs={{ size: 12 }} lg={{ size: 6 }}>
            <ListGroupItem className="justify-content-between" style={{marginTop: 20}}>
              <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Robot Personality</b></h3>
              <div>People have different preferences when it comes to the personality of an interaction partner. When
                designing
                human-robot-interactions, robots are usually equipped with one hand-authored and pre-defined
                personality.
                I am interested in understanding how robots can express different coherent and consistent personalities.
                A crucial part in developing personalities is being able to communicate the robot's current mood
                and create a natural progress of the mood over time.
              </div>
            </ListGroupItem>
          </Col>
        </Row>
        <Row>
          <Col xs={{ size: 12 }} lg={{ size: 6 }}>
            <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
              <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Building an Artificial Game Partner</b></h3>
              <div> The map-game poses several challenges for an agent to play the game autonomously,
                especially when it comes to the Natural Language Understanding (NLU) part of the system.
                We are currently working on the NLU for the role of the Matcher, which needs to reason between
                all 195 countries in the world that the Director could potentially be describing. One natural
                language processing problem of particular interest in this task is coreference resolution.
              </div>
            </ListGroupItem>
          </Col>
          <Col xs={{ size: 12 }} lg={{ size: 6 }}>
            <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
              <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Modelling the User's Engagement</b></h3>
              <div>As part of project COIN (Co-adaptive human-robot interactive systems) funded by the Swedish
                Foundation for Strategic Research (SSF)
                we work on tracking the user's engagement when playing the game. Different sources are utilized for
                this, among them gaze patterns
                from the Tobii eyetracking glasses,
                facial expressions and prosody. The overall goal is to keep the user engaged
                in the game by adapting the dialogue strategy of the agent when needed.
              </div>
            </ListGroupItem>
          </Col>
        </Row>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>RoboCup</b></h3>
        <img style={{ width: 200, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/robocup.png'} alt={""} />
        <div>
          <p>Together with other students from the University of Hamburg, I founded the RoboCup team Hamburg Bit-Bots in
            2011. Since 2012,
            we have been competing in the GermanOpen and the world championship every year. While I was still in
            Hamburg, I mostly contributed
            to the robot behavior, localization on the field and team communication. I've also been involved in the
            organization of the team, managing the trips
            to the tournaments and findings sponsors.</p>
          <p>Since I left Hamburg in 2015, I got involved in the organization of RoboCup,
            first in the Technical Committee and Executive Committee of the Humanoid League, and since 2023 I am serving
          on the Board of Trustees.</p>
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, marginBottom: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Mimicry of Artificial Agents</b></h3>
        <div>
          <img style={{ width: 200, float: "left", marginRight: 10 }}
               src={process.env.PUBLIC_URL + '/resources/mimicry.jpg'} alt={""} />
          <p>In a collaboration with researchers at the Institut des Systèmes Intelligents et de Robotique, Université
            Pierre et Marie Curie,
            we investigate what influences people's mimicry of an artificial agent with a specific focus on potential
            uncanny feelings
            towards the artificial agent. One aim of the project is to research if mimicry can be used as a potential
            future assessment criteria for uncanny feelings towards a robot.</p>
          <p> We use both the co-present mixed-embodied Furhat robot, a video recording of Furhat and a 2D virtual
            character
            and compare how the embodiment influences people's mimicry towards the agent. We measure both spontaneous as
            well as
            instructed mimicry and analyze the mimicry using computer-vision techniques.</p>
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, minHeight: 160 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Designing for the Uncanny</b></h3>
        <div>
          <img style={{ height: 100, float: "left", marginRight: 10 }}
               src={process.env.PUBLIC_URL + '/resources/dragon.png'} alt={""} />
          <div>By analyzing the interaction strategies of a street performer with his dragon puppet, we develop design
            guidelines for what
            creates the feeling of uncanny and how to overcome this feeling using interaction techniques.
          </div>
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, minHeight: 210 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Gender and the Uncanny</b></h3>
        <img style={{ width: 200, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/gender.png'} alt={""} />
        <div>We aim to broaden the knowledge of the uncanny valley effect by analyzing the interplay between perceptual
          mismatch
          in the category of gender. We use the Furhat mixed-embodied robot platform with a female and male appearance
          and voice.
          By altering different cues and thereby creating a perceptual mismatch, we aim to understand what triggers the
          feeling of uncanny in different age groups.
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Eve – a Dialogue Agent Playing an Image Matching Game</b></h3>
        <img style={{ width: 200, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/rdg-lab_director.png'} alt={""} />
        <div>As part of the NSF founded project "Incremental Speech Processing for Rapid Dialogue" we developed
          a dialogue agent that can engage in a two-player image matching game. In the game, a human acts
          as a Director and describes one out of eight images they see on the screen. The automated
          matcher dialogue system processes the speech of the director and selects the most likely image
          candidate as soon as possible. The dialogue strategy of the agent is optimized to maximize the points the team
          scores per second.
        </div>
      </ListGroupItem>
    </div>
  </Container>

}

export default withRouter(Research)