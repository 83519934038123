import React from 'react';
import { Navbar, NavbarBrand, NavbarToggler, } from "reactstrap";
import { withRouter } from "react-router-dom";

const Header = ({ toggle }) => {

  return (
    <div>
      <Navbar light expand="md" style={{ backgroundColor: "#432342" }} fixed={"top"}>
        <NavbarBrand style={{ color: "#F0EFF4" }} href="/">Maike Paetzel-Prüsmann, Ph.D.</NavbarBrand>
        <NavbarToggler className={"navbar-dark"} onClick={() => toggle()} />
      </Navbar>
    </div>
  );
}

export default withRouter(Header)
