import React, { useState } from 'react'
import ContentRouter from "./routing";
import Header from "./scenes/Header";
import { Router as BrowserRouter } from "react-router-dom";
import history from "./history";
import SideNav from "./scenes/SideNav";
import './App.scss'

const App = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <div style={{ backgroundColor: "#F0EFF4", minHeight: "100vh" }}>
      <BrowserRouter history={history}>
        <div>
          <Header toggle={() => setIsSidebarOpen(prev => !prev)} />
          <div style={{ display: "flex" }}>
            <SideNav isOpen={isSidebarOpen} close={() => setIsSidebarOpen(false)}/>
            <ContentRouter />
          </div>
        </div>
      </BrowserRouter>
    </div>
  )
}

export default App
