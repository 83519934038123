import { withRouter } from "react-router-dom";
import { Container, ListGroupItem } from "reactstrap";
import React, { useEffect, useState } from "react";
import BibtexParse from "bibtex-parse-js";
import "./AcademicServices.scss"

const AcademicServices = () => {


  return <Container fluid style={{ paddingTop: 56 + 16, paddingBottom: 40 }}>
    <div className="content-frame">
      <h1 style={{ fontWeight: "heavy", marginBottom: 20, color: "#432342" }}>Academic Services</h1>
      <div style={{ height: 24 }} />
      <h3 style={{ fontWeight: "heavy", color: "#5C475A" }}>Committees & Volunteering</h3>
      <div style={{ height: 8 }} />

      <dl>
        <dt>2023 - present</dt>

        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Grace Hopper Celebration</b></h3>
          Content Council Member
          <h3 style={{ marginTop: 20, marginBottom: 10, fontSize: '1rem' }}><b>RoboCup Trustee</b></h3>
          <h3 style={{ marginTop: 20, marginBottom: 10, fontSize: '1rem' }}><b>RoboCup Committee Germany</b></h3>
          Humanoid League Representative
        </dd>

        <div className={"clear"} style={{ height: 20 }} />
        <dt>2018 - 2023</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>RoboCup Humanoid Executive Committee</b></h3>
          Elected Member
        </dd>
        <div className={"clear"} style={{ height: 20 }} />
        <dt>2017 - 2023 </dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>RoboCup Humanoid Organizing Committee</b></h3>
          Elected Member
          <p>2017, 2020 and 2021: Committee Chair</p>
        </dd>
        <div className={"clear"} style={{ height: 20 }} />
        <dt>2021 - 2023 </dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>IEEE Robotics and Automation Letters</b></h3>
          Associate Editor
        </dd>

        <div className={"clear"} style={{ height: 20 }} />
        <dt>2023</dt>

        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>ACM Conference on Conversational User Interfaces</b></h3>
          Eindhoven, The Netherlands
          <p>Student Volunteer Co-Chair</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Language-Based AI Agent Interaction with Children</b></h3>
          Hybrid Workshop colocated with IWSDS 2023 in Los Angeles, USA
          <p>Workshop Co-Organizer</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>RoboCup Symposium 2022</b></h3>
          25th RoboCup International Symposium to be held in conjunction with RoboCup 2022 in Bangkok, Thailand
          <p>Program Committee Co-Chair</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Intelligent Virtual Agents 2020</b></h3>
          22nd ACM International Conference on Intelligent Virtual Agents (IVA) in Algarve, Portugal
          <p>Doctoral Consortium Co-Chair</p>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />
        <dt>2022</dt>

        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Workshop Gendering Robots: Intersectional Feminist Perspectives on Gender
            in Social Robotics</b></h3>
          RO-MAN 2022 conference in Naples, Italy and online
          <p>Workshop Co-Organizer</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Retico - An Introduction to Building Incremental Dialogue Systems in Python</b></h3>
          Conference on Natural Language Processing, Potsdam, Germany
          <p>Tutorial Co-Organizer</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>RoboCup Symposium 2022</b></h3>
          25th RoboCup International Symposium to be held in conjunction with RoboCup 2022 in Bangkok, Thailand
          <p>Program Committee Co-Chair</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Intelligent Virtual Agents 2020</b></h3>
          22nd ACM International Conference on Intelligent Virtual Agents (IVA) in Algarve, Portugal
          <p>Doctoral Consortium Co-Chair</p>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />
        <dt>2021</dt>

        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>SemDial 2021</b></h3>
          25th Workshop on the Semantics and Pragmatics of Dialogue to be held in Potsdam and virtually in September 2021
          <a href={"https://semdial2021.ling.uni-potsdam.de/"} target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}>
            <img src={process.env.PUBLIC_URL + '/resources/link.png'} style={{marginLeft:5, height: 15 }} /> Workshop Website</a>
          <p>Part of the local organizing committee</p>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />
        <dt>2020</dt>

        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Modeling Socio-Emotional and Cognitive Processes from Multimodal Data in
            the Wild</b></h3>
          Workshop to be held virtually during the 22nd ACM International Conference on Multimodal Interaction
          <a href={"https://sites.google.com/view/modeling-multimodal-data/"} target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}>
            <img src={process.env.PUBLIC_URL + '/resources/link.png'} style={{marginLeft:5, height: 15 }} /> Workshop Website</a>
          <p>Member of the Organizing Committee</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Virtual RoboCup Humanoid Open Workshops (V-RoHOW)</b></h3>
          Workshop held virtually instead of the 2020 RoboCup world championship
          <a href={"https://humanoid.robocup.org/virtual-rohow-2020/"} target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}>
            <img src={process.env.PUBLIC_URL + '/resources/link.png'} style={{marginLeft:5, height: 15 }} /> Workshop Website</a>
          <p>Main Organizer</p>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2019</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Young Researchers Roundtable on Spoken Dialogue Systems (YRRSDS)</b></h3>
          <a href={"https://sites.google.com/site/yrrsds2019/home"} target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}>
            <img src={process.env.PUBLIC_URL + '/resources/link.png'} style={{marginLeft:5, height: 15 }} /> YRRSDS Website</a>
          <p>Member of the Organizing Committee</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>20th AnnualMeeting of the Special Interest Group on Discourse and Dialogue (SigDial)</b></h3>
          <p>Student Volunteer</p>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2017</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>17th International Conference on Intelligent Virtual Agents (IVA)</b></h3>
          <p>Publicity Chair</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Young Researchers Roundtable on Spoken Dialogue Systems (YRRSDS)</b></h3>
          <a href={"https://sites.google.com/site/2017yrrsds/"} target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}>
            <img src={process.env.PUBLIC_URL + '/resources/link.png'} style={{marginLeft:5, height: 15 }} /> YRRSDS Website</a>
          <p>Member of the Organizing Committee</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>RoboCup Humanoid League Technical Committee</b></h3>
          <p>Elected Member</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>12th ACM/IEEE International Conference on Human-Robot Interaction (HRI)</b></h3>
          <p>Student Volunteer</p>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2016</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>17th AnnualMeeting of the Special Interest Group on Discourse and Dialogue (SigDial)</b></h3>
          <p>Student Volunteer</p>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2016</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>AI challenges for soccer-playing humanoid robots</b></h3>
          Workshop held as part of the 38th German Conference on Artificial Intelligence
          <a href={"https://www.robocuphumanoid.org/ki2015/"} target={"_blank"} style={{ color: "#5C475A", whiteSpace: "nowrap" }}>
            <img src={process.env.PUBLIC_URL + '/resources/link.png'} style={{marginLeft:5, height: 15 }} /> Workshop Website</a>
          <p>Student Volunteer</p>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>RoboCup Humanoid League Technical Committee</b></h3>
          <p>Elected Member</p>
        </dd>
      </dl>

      <div className={"clear"} style={{ height: 24 }} />
      <h3 style={{ fontWeight: "heavy", color: "#5C475A" }}>Reviewing</h3>
      <div style={{ height: 8 }} />
      <dl>
        <dt>2024</dt>
        <dd>
          <li>The 26th ACM International Conference on Multimodal Interaction (ICMI 2024)</li>
          <li>The 12th International Conference on Affective Computing and Intelligent Interaction (ACII 2024)</li>
          <li>The 52st ACM Special Interest Group on Computer Graphics and Interactive Techniques
            (SIGGRAPH)</li>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />
        <dt>2023</dt>
        <dd>
          <li>14th International Workshop on Spoken Dialogue Systems Technology (IWSDS 2024)</li>
          <li>Dialogue & Discourse Journal</li>
          <li>2024 ACM/IEEE International Conference on Human-Robot Interaction (HRI)</li>
          <li>The 27th RoboCup International Symposium</li>
          <li>Workshop Inclusive HRI II: Equity and Diversity in Design, Application, Methods, and Community</li>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />
        <dt>2022</dt>
        <dd>
          <li>International Journal of Social Robotics</li>
          <li>18th Annual ACM/IEEE International Conference on Human-Robot Interaction (HRI)</li>
          <li>13th International Workshop on Spoken Dialogue Systems Technology (IWSDS 2023)</li>
          <li>ACL Rolling Review</li>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />
        <dt>2021</dt>
        <dd>
          <li>International Journal of Social Robotics</li>
          <li>International Journal of Human-Computer Interaction</li>
          <li>European Journal of STEM Education</li>
          <li>25th Workshop on the Semantics and Pragmatics of Dialogue</li>
          <li>30th IEEE International Conference on Robot & Human Interactive Communication</li>
          <li>9th International Conference on Affective Computing & Intelligent Interaction</li>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2020</dt>
        <dd>
          <li>16th ACM/IEEE International Conference on Human-Robot Interaction</li>
          <li>Transactions on Human-Robot Interaction</li>
          <li>29th IEEE International Conference on Robot & Human Interactive Communication</li>
          <li>International Journal of Social Robotics</li>
          <li>Robotics: Science and Systems Conference</li>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2019</dt>
        <dd>
          <li>15th ACM/IEEE International Conference on Human-Robot Interaction</li>
          <li>RoboCup Symposium</li>
          <li>International Journal of Social Robotics</li>
          <li>8th International Conference on Affective Computing & Intelligent Interaction</li>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2018</dt>
        <dd>
          <li>14th ACM/IEEE International Conference on Human-Robot Interaction</li>
          <li>International Journal of Social Robotics</li>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2017</dt>
        <dd>
          <li>13th ACM/IEEE International Conference on Human-Robot Interaction</li>
          <li>International Journal of Social Robotics</li>
          <li>5th annual International Conference on Human-Agent Interaction</li>
          <li>2017 IEEE/RSJ International Conference on Intelligent Robots and Systems</li>
        </dd>

        <div className={"clear"} style={{ height: 20 }} />

        <dt>2016</dt>
        <dd>
          <li>12th ACM/IEEE International Conference on Human-Robot Interaction</li>
          <li>8th International Conference on Social Robotics</li>
          <li>International Conference on Pattern Recognition</li>
          <li>Intelligent Service Robotics Journal</li>
        </dd>
      </dl>
      <div className={"clear"} style={{ height: 24 }} />
      <h3 style={{ fontWeight: "heavy", color: "#5C475A" }}>Student & Academic Self-Governance</h3>
      <div style={{ height: 8 }} />
      <dl>
        <dt>2018</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Search Committee for Head of Division</b></h3>
          Student Member
        </dd>
        <div className={"clear"} style={{ height: 20 }} />
        <dt>2012 – 2015</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Parliamentarian in the Student Parliament at the University of Hamburg</b></h3>
        </dd>
        <div className={"clear"} style={{ height: 20 }} />
        <dt>2015</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Elected speaker for the office of ecology and sustainable development at the General Student Union (AStA), University of Hamburg</b></h3>
        </dd>
        <div className={"clear"} style={{ height: 20 }} />
        <dt>2014</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b> Committee for Planning and Budget at the University of Hamburg</b></h3>
          Elected Student Member
        </dd>
        <div className={"clear"} style={{ height: 20 }} />
        <dt>2013 – 2015</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Leader of the student association CampusGrün at the University of Hamburg</b></h3>
        </dd>
        <div className={"clear"} style={{ height: 20 }} />
        <dt>2013 – 2014</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Library Committee at the Department of Informatics</b></h3>
          Elected Student Member
        </dd>
        <div className={"clear"} style={{ height: 20 }} />
        <dt>2011, 2015</dt>
        <dd>
          <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Selection Committee for Professorship</b></h3>
          Student Representative
        </dd>
      </dl>
    </div>
  </Container>

}

export default withRouter(AcademicServices)