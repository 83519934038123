import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, ListGroupItem } from 'reactstrap'

class ShortBio extends Component {

  render() {
    return <Container fluid>
      <div className="content-frame">

        <div style={{ paddingTop: 56 + 16, paddingBottom: 40 }}>
          <h1 style={{ fontWeight: "heavy", marginBottom: 20, color: "#432342" }}>A Short Biography</h1>
          <div style={{ fontSize: 20 }}>
            <p style={{ marginBottom: 20 }}>I grew up in Germany's second biggest city Hamburg where I attended highschool and
              studied in the Computer Science Bachelor and Master program. During my highschool years, I was most interested in writing.
              I attended several writing seminars, wrote for the school magazine and eventually became its chief editor.
              Computer Science was one of my least favorite subjects in school for the first two years, which was mostly because it was entirely
              focused on learning Microsoft Word and Excel. I was still curious when I saw and advertisement for a one-week University program for highschool students
              in the autumn school break. I read more about the program online and it sounded very different from learning Excel and Word. In fact, it had
              one project that I immediately fell in love with: Soccer playing robot dogs! I registered for the program and got into the robotics project, where I met
              Janis Schönefeld who was studying for his PhD at the University of Hamburg at the time. Janis was very passionate about his robotics research and
              became my first mentor. I visited the student project for soccer playing robots a couple of times after the week was over and became more and more
              interested in Computer Science.
            </p>
            <p> By the end of my 11th grade, I was still uncertain whether Computer Science was still the right study program for me.
              Even though I had just participated in a one-year program called e-truck in which we teamed up with University students and mechatronics apprentices
              to build a robot and participate in a tournament, I feared that I would not be prepared enough for studying Computer Science.
              I decided to apply to start studying Computer Science while I was still in highschool, a possibility given to students with very good grades in Hamburg.
              I figured this would give me the opportunity to try the program without losing time after highschool if it was really not for me.
              In October 2012, I thus started studying Computer Science, or more specifically Software Development I.

            </p>
          </div>
        </div>
      </div>
    </Container>

  }
}

export default withRouter(ShortBio)
