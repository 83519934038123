import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import Landing from "./scenes/LandingPage";
import PublicationsContainer from "./scenes/PulicationsContainer";
import Teaching from "./scenes/Teaching";
import Research from "./scenes/Research";
import Outreach from "./scenes/Outreach";
import AcademicServices from "./scenes/AcademicServices";
import ShortBio from "./scenes/ShortBio";

export default class ContentRouter extends Component {
    render() {
            return <Switch>
                <Route path='/publications' component={PublicationsContainer}/>
                <Route path='/teaching' component={Teaching}/>
                <Route path='/research-projects' component={Research}/>
                <Route path='/scientific-outreach' component={Outreach}/>
                <Route path='/academic-services' component={AcademicServices}/>

                <Route path='/' component={Landing}/>
            </Switch>
    }
}