import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, ListGroupItem } from 'reactstrap'

class LandingPage extends Component {

  render() {
    return <Container fluid>
      <div className="content-frame" style={{ paddingBottom: 40 }}>

        <div style={{ paddingTop: 56 + 16}}>
          <h1 style={{ fontWeight: "heavy", marginBottom: 20, color: "#432342" }}>About Me</h1>
          <div style={{ fontSize: 20 }}>
            <p style={{ marginBottom: 20 }}>I'm an Associate Research Scientist
              at <a href="https://la.disneyresearch.com/" style={{ color: "#5C475A", whiteSpace: "nowrap" }}>Disney Research Imagineering</a> where
              I research how humans interact with AI characters. Before joining Disney, I received my PhD from Uppsala University, Sweden, in
              Computer Science with a Specialization in Human-Computer Interaction in October 2020 and did a PostDoc at
              the colab Potsdam (University of Potsdam, Germany).
            </p>
            <p>Originally I studied Computer Science at the University of Hamburg, Germany, and focused on both
              humanoid robotics and conversational interaction. I wrote my Master thesis in cooperation with the University of Southern California on
              building an incremental dialogue system.
              Apart from Human-Robot Interaction, my heart belongs to the RoboCup community.
              Since 2011 I'm involved in the RoboCup Humanoid League, first as one of the founders of the
              team <a href="https://bit-bots.de/" style={{ color: "#5C475A", whiteSpace: "nowrap" }}>Hamburg Bit-Bots</a> and
              since 2015, I'm actively engaged in the RoboCup Organization. Since 2023, I am serving on the board of Trustees of the RoboCup Federation.
            </p>
            <p>
              When I'm not researching or tinkering with robots, you will most likely find me on the dance floor dancing
              Kizomba, Urban Kiz, Bachata or ballroom dances, bouldering, playing cooperative boardgames with friends,
              or reading a book.
            </p>
          </div>
        </div>
        <div style={{ marginTop: 40 }}>
          <h1 style={{ fontWeight: "heavy", marginBottom: 20, color: "#432342" }}>News</h1>
          <ListGroupItem className="justify-content-between" style={{ marginTop: 10 }}>
            <h4 style={{ fontWeight: "heavy", color: "#5C475A" }}>Paper accepted at IVA 2024!</h4>
            <div>
              Last year, I co-supervised Ronald Cumbal during his summer internship at Disney Research and now we have a paper accepted
              detailing the research he conducted then! The title is "Let me finish first – The effect of
              interruption-handling strategy on the perceived personality of a social agent". Ronald will present the
              work in the Dialogue session on Tuesday, september 17.
            </div>
            <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
              Published: 04/08/2024
            </div>
          </ListGroupItem>
          <ListGroupItem className="justify-content-between" style={{ marginTop: 10 }}>
            <h4 style={{ fontWeight: "heavy", color: "#5C475A" }}>RoboCup Journal article published!</h4>
            <div>
              After a several months of work with a great team of researchers across countries and research disciplines,
              we are proud to have our article: "The human in the loop - Perspectives and challenges for RoboCup 2050"
              published by the Springer Autonomous Robots journal! You can read the paper <a href="https://link.springer.com/article/10.1007/s10514-024-10159-3" style={{ color: "#5C475A", whiteSpace: "nowrap" }}>here</a>.
            </div>
            <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
              Published: 20/05/2024
            </div>
          </ListGroupItem>
          <ListGroupItem className="justify-content-between" style={{ marginTop: 10 }}>
            <h4 style={{ fontWeight: "heavy", color: "#5C475A" }}>Best Presentation Award at TAHRI</h4>
            <div>
              Our paper "An Automatic Evaluation Framework for Social Conversations with Robots" just won the best
              presentation award at the 2024 International Symposium on Technological Advances in Human-Robot Interaction in
              Boulder, Colorado! I am so excited to present work so well received by the HRI community
            </div>
            <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
              Published: 11/02/2024
            </div>
          </ListGroupItem>
          <ListGroupItem className="justify-content-between" style={{ marginTop: 10 }}>
            <h4 style={{ fontWeight: "heavy", color: "#5C475A" }}>Presenting at HRI & TAHRI</h4>
            <div>
              I will be attending both the 2024 International Symposium on Technological Advances in Human-Robot Interaction (TAHRI) and
              the 2024 ACM/IEEE International Conference on Human-Robot Interaction (HRI) in Boulder, Colorado this March.
              At TAHRI, I will present our work on developing an automatic evaluation framework for social conversations with
              robots; at HRI, I will support in presenting a poster about name pronunciation extraction and reuse in human-agent conversation.
            </div>
            <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
              Published: 01/03/2024
            </div>
          </ListGroupItem>
        </div>
      </div>
    </Container>

  }
}

export default withRouter(LandingPage)
