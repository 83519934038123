import { Container, ListGroupItem } from "reactstrap";
import React from "react";
import { withRouter } from "react-router";

const Outreach = () => {

  return <Container fluid style={{ paddingTop: 56 + 16, paddingBottom: 40 }}>
    <div className="content-frame">
      <h1 style={{ fontWeight: "heavy", marginBottom: 20, color: "#432342" }}>Scientific Outreach</h1>
      <div style={{ height: 8 }} />
      <div>
        <p>I consider scientific outreach not just an important part of being a scientist, it is also one of my great
          passions.
          Outreach does not only involve engaging people in critical thinking about the potentials and risks of robots.
          It especially means creating a fascination towards robots in children, especially in young girls,
          and thereby influencing their later choice of study is an excellent opportunity to create a diversity in the
          Computer Science and robotics community.</p>
        <p>In the following, I created an overview of the different projects I was involved in over the years.</p>
      </div>
      <div style={{ height: 12 }} />
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20}}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Open Teaching Material</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/yt.png'} alt={""} />
        <div>
          <p>I believe in open science as well as teaching material that is accessible for everyone. Hence, I took the
            opportunity of the Pandemic where all lectures had to be pre-recorded at home, to create a series of videos
            on YouTube that are still available. And while I don't manage to maintain the channel and create more videos
            anymore, the videos have still gathered a couple of thousand views across the years.
          </p>
          <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
            2021, 2022
          </div>
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20}}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Media and Newspaper Features</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/netzpolitik.png'} alt={""} />
        <div>
          <p>Over the years, I was interviewed by a couple of newspapers, radio shows and podcasts, both on a regional
            and national level in Germany as well as in Sweden. Most recently, I was featured in
            the <a href="https://www.maz-online.de/lokales/potsdam/universitaet-potsdam-expertin-der-computerlinguistik-arbeitet-an-der-interaktion-zwischen-mensch-und-OJCTDX52H6JRLLMWMPCROIWWJQ.html" style={{ color: "#5C475A", whiteSpace: "nowrap" }}>Märkische Allgemeine Zeitung</a>, a
            regional newspaper from Brandenburg, Germany, as well as in
            the <a href="https://netzpolitik.org/2022/npp-255-zu-kuenstlicher-intelligenz-mein-freund-der-roboter/l" style={{ color: "#5C475A", whiteSpace: "nowrap" }}>Netzpolitik.org Podcast</a> about
            Artificial Intelligence.
          </p>
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, minHeight: 240 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Soapbox Science Berlin</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/soapbox.jpg'} alt={""} />
        <div>
          <p>Soapbox Science is a novel public outreach platform for promoting women and non-binary scientists and the science they do.
          In 2022, I was selected to be one of the speakers at the Berlin Soapbox Science event. I brought a couple of
            robots with me, and talked about why scientists care to make robots that play soccer, and why it is still
            such a hard task for them to master.
          </p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2022
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Tabula Rasa - Wissenschaft zum Anfassen</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/tabularasa.png'} alt={""} />
        <div>
          <p>As part of the Berlin Science Week, Urania Berlin organized a poster session for Berlin-based
          scientists to showcase their research to the general public. With the RoboCup Humanoid Soccer team
          01. RFC Berlin I presented the first prototype of our new robot platform and our simulated robots
            doing a live penalty shoot-out in the simulated environment from the virtual RoboCup world championship 2021.
          Apart from hundreds of visitors we even had the mayor of the city stop by and say hi!</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2021
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Potsdamer Tage der Wissenschaften 2021 (Potsdam Science Days)</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/ptdw.jpg'} alt={""} />
        <div>
          <p>During the Potsdam Science Days, the different research institutes in Potsdam showcase their research to the
          general public. In 2021, the event was organized fully virtual and I was part of the organization of two different activities:
          Together with the 01. RFC Berlin RoboCup team, I created a video teaching children how a soccer playing robot is built.
          With the computational linguistics lab, we presented some of our interactive dialogue systems, student projects as
          well as a live session in which we talked about our research.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2021, 2022
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Girl's Day</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/girlsday.jpg'} alt={""} />
        <div>
          <p>The Girl's Day is an annual event in which girls and boys try out professions in which their gender is still
            in the minority. Since female students are still in the minority in computer science, computer science departments
            at Universities often organize one-day projects to showcase what studying computer science is all about.
            In 2021, I organized an online workshop at the Freie Universität Berlin with the 01. RFC Berlin RoboCup team,
            teaching girls how to program soccer playing robots.
            In 2011, I was co-organizer in a project on Dialogue Systems at the University of Hamburg and in 2010, I was
            part of the Girl's Day project of the RoboCup team Hamburg Bit-Bots.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2010, 2011, 2021, 2022
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, minHeight: 120 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>SciFest</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/scifest.png'} alt={""} />
        <div><p>SciFest is an annual three day science festival for children in Uppsala with more than 8000 visitors.
          I have organized a booth for the Social Robotics Lab almost every year since I started my PhD in Uppsala
          In our exhibition, children can learn how to program and control a robot or
          participate in a real scientific experiment. Our student volunteers engaged children between the age of 8
          and 18 in
          interactions with three NAO robots, Furhat, Pepper and Cozmos.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2016, 2017, 2019, 2020
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Technology Days</b></h3>
        <div>
          <p>The purpose of the Technology Days is to get more high school girls interested in technical
            and natural science subjects and thus help them to choose their specializations in upper secondary
            school and in higher education. Half a day of the three day long program the girls spend with the
            Social Robotics Lab: They first learned how to animate and program a NAO robot and then we showed
            them demonstrations of real research projects we are working on in the lab.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2018
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, minHeight: 120 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Heise Online: RoboCup </b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/heise.png'} alt={""} />
        <div>
          <p>Heise Online, one of the most well-known IT news websites in Germany, gave the German
            RoboCup teams an opportunity to present an insight into their research.
            I gathered contributions from all German Humanoid League RoboCup teams and wrote an
            introduction to the league and its main research problems based on them.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2017
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Refugee visit at the Social Robotics Lab</b></h3>
        <div>
          <p>Two school classes from the LYSA preparation program visited our Social Robotics Lab. Teenagers
            who came to Sweden recently attend the classes to learn Swedish and prepare to attend the Swedish
            high school afterwards. The aim of the visit was to inspire the teenagers what possibilities
            they have after school and give them a basis for deciding what majors to pick for high school.
            The teenagers first learned some basics about robotics before they could interact with the robots
            themselves. They even taught the robots some simple Persian language!</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2016
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, minHeight: 120 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>University of Hamburg - Open House</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/tag_der_offenen_tuer.jpg'} alt={""} />
        <div>
          <p>The selection of the right university to study can be challenging for students.
            The Department of Informatics participates every year with different lectures and
            activities in the open house at the University of Hamburg. The RoboCup team Hamburg
            Bit-Bots demonstrates their robots for the future students and offers a hands-on experience
            what studying computer science can be about. In addition, students from different bachelor programs
            give an insight in the subject of informatics from a student perspective.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2013, 2014, 2015
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20, minHeight: 120 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Türen auf für die Maus</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/maus.png'} alt={""} />
        <div>
          <p>Once a year a popular German television show (“Die Sendung mit der Maus”) organizes an open house
            in different companies and institutes all over Germany. Young children can get an exclusive look
            behind the scenes and ask all the questions they always wanted to know – in our case about robots.
            Girls and boys could help us assemble and program our robots and learn how we teach our robots to play
            soccer. Early experiences highly impact children’s later live choices – engaging them early on in
            robotics is therefore an excellent opportunity to make this field a more popular study choice.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2015
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Soviel du brauchst – 32. Deutscher Evangelische Kirchentag in Hamburg</b>
        </h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/kirchentag.jpg'} alt={""} />
        <div>
          <p>Religion and science – does that go together? When Hamburg hosted the German Protestant Church Congress
            in 2013, a whole day was designated to the sciences. Institutes and research groups prepared
            lectures and seminars about different areas in science. The RoboCup team Hamburg Bit-Bots supported a
            series of lectures on the future of humanity under the influence of robots with some demonstrations of
            soccer robots.
            A good practical addition to the theoretical foundations in the lectures.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2013
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Robots on Tour</b></h3>
        <img style={{ width: 120, float: "left", marginRight: 10 }}
             src={process.env.PUBLIC_URL + '/resources/robots_on_tour.jpg'} alt={""} />
        <div>
          <p>The University of Zurich invited robots from all over the world to celebrate 25 Years AI Lab Zurich.
            The RoboCup Humanoid team Hamburg Bit-Bots demonstrated their soccer abilities in demo games against
            the RoboCup Standard Platform League team Nao Devils. Before the fair was open to the general public,
            a whole day was reserved for school classes to learn more about the field of robotics.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2013
        </div>
      </ListGroupItem>
      <ListGroupItem className="justify-content-between" style={{ marginTop: 20 }}>
        <h3 style={{ marginBottom: 10, fontSize: '1rem' }}><b>Schnupperstudium</b></h3>
        <div>
          <p>Each year, the Department of Informatics at the University of Hamburg offers highschool students
            to try out computer science studies. Apart from lectures, students work the majority of time
            in a project that is presented to the others in the end of the week. The RoboCup project welcomes
            one group of students each year to work with their robots. Depending on their knowledge level,
            the students can engage in different programming activities and teach our robots how to play soccer.</p>
        </div>
        <div style={{ fontSize: 14, textAlign: "right", marginTop: 10 }}>
          2010, 2011
        </div>
      </ListGroupItem>
    </div>
  </Container>

}

export default withRouter(Outreach)